<template>
  <Popover @sureHandler="clickHandler(data, $event)">
    <template #tip>
      <p>确定禁用?</p>
    </template>
    <template #reference="{ scope: loading }">
      <ColorTextBtn class="delete-btn" :loading="loading" type="danger"> 禁用 </ColorTextBtn>
    </template>
  </Popover>
</template>

<script>
import attributeApi from '@/api/productTemplate/attributeManage'

export default {
  props: {
    data: Object,
    sup_this: Object
  },

  data() {
    return {
      DISABLE: 0
    }
  },

  methods: {
    async clickHandler(data, e) {
      const res = await awaitResolve(attributeApi.update({
        id: data.id,
        isEnable: DISABLE,
      }))
      if(!res) return
      this.sup_this.init()
      this.$message.success('操作成功')
    }
  }
}
</script>